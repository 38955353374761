import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AuditLog, AuditLogMessageData, AuditLogStatus, JumptechDate, ProjectState } from '@jump-tech-frontend/domain';
import { ProjectAuditLogsPresenter } from './project-audit-logs.presenter';
import { BehaviorSubject } from 'rxjs';
import { ProjectAuditLogsVm, LOCALE_CONTEXT } from './project-audit-logs.model';
import { CommonModule, DatePipe } from '@angular/common';
import { ProjectAuditLogsRepository } from './project-audit-logs.repository';
import { TranslocoModule, TranslocoPipe } from '@ngneat/transloco';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { ValueSanitiserPipe } from '@jump-tech-frontend/angular-common';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { CamelToReadablePipe } from '../../shared/pipes/camel-to-readable.pipe';
import { ProjectAuditLogsErrorsComponent } from './errors/project-audit-logs-errors.component';
import { ProjectAuditLogsErrorsPresenter } from './errors/project-audit-logs-errors.presenter';

@Component({
  selector: 'app-project-audit-logs',
  templateUrl: 'project-audit-logs.component.html',
  styleUrls: ['./project-audit-logs.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslocoModule,
    CoreComponentsAngularModule,
    NgbAlertModule,
    ValueSanitiserPipe,
    CamelToReadablePipe,
    ProjectAuditLogsErrorsComponent
  ],
  providers: [
    ProjectAuditLogsPresenter,
    ProjectAuditLogsErrorsPresenter,
    ProjectAuditLogsRepository,
    TranslocoPipe,
    DatePipe
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectAuditLogsComponent implements OnInit, OnDestroy {
  public vm$: BehaviorSubject<ProjectAuditLogsVm> = new BehaviorSubject<ProjectAuditLogsVm>(null);
  public localeContext = LOCALE_CONTEXT;
  public loaderRange = Array.from({ length: 10 });

  constructor(
    private presenter: ProjectAuditLogsPresenter,
    private transloco: TranslocoPipe,
    protected router: Router
  ) {}
  @Input() projectId: string;
  @Input() tenant: string;
  @Input() logsProvided = false;
  @Input() states: ProjectState[];

  async ngOnInit() {
    if (!this.logsProvided) {
      await this.presenter.load(this.projectId, this.vm$);
    }
  }

  ngOnDestroy() {
    this.presenter.unload();
  }

  setVm(vm: ProjectAuditLogsVm) {
    this.vm$.next(vm);
    this.presenter.setVm(this.vm$);
  }

  /**
   * Formatting data items from audit logs depending on their type so that they can be displayed correctly.
   * Dates are passed through the native date pipe (note: currently not localised).
   * Project status labels are looked up from the project configuration states.
   * Message key values are passed through transloco with 'auditLogs.' appended to them
   * Everything else is treated as a string which is put through transloco in the format 'auditLogs.dataItemKey'
   * @param dataItem A MessageData object from an audit log with a key, value and type.
   */
  displayDataItemValue(dataItem: AuditLogMessageData): string {
    switch (dataItem.type) {
      case 'isoDate':
        return this.displayLocalisedDateValue(dataItem.value);
      case 'projectStatus':
        return this.states.find(state => state.status === dataItem.value)?.label || dataItem.value;
      case 'messageKey':
        return this.transloco.transform(`${this.localeContext}.${dataItem.value}`);
      default:
        return dataItem.value;
    }
  }

  displayDataItemKey(dataItemKey: string): string {
    return this.transloco.transform(`${this.localeContext}.${dataItemKey}`);
  }

  displayLocalisedDateValue(date: string): string {
    return JumptechDate.from(date).toDateTimeFormat();
  }

  displayEventName(auditLog: AuditLog): string {
    const messageKeyToTranslate = `${this.localeContext}.${auditLog.messageOptions.key}`;
    const translocoResult = this.transloco.transform(messageKeyToTranslate);
    if (translocoResult === messageKeyToTranslate) {
      // No translation key has been found in any language, use fallback text
      return auditLog.messageOptions.text;
    }
    return translocoResult;
  }

  goToProject(projectId: string): void {
    this.router.navigate([`/project/${projectId}`]).catch(console.log);
  }

  protected readonly AuditLogStatus = AuditLogStatus;
}
