import { Component, OnInit } from '@angular/core';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { CommonModule } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { ProjectAuditLogsError } from '../project-audit-logs.model';
import { ProjectAuditLogsErrorsPresenter } from './project-audit-logs-errors.presenter';

@Component({
  selector: 'project-audit-logs-errors',
  standalone: true,
  imports: [CommonModule, CoreComponentsAngularModule],
  templateUrl: './project-audit-logs-errors.component.html',
  styleUrl: 'project-audit-logs-errors.component.scss'
})
export class ProjectAuditLogsErrorsComponent implements OnInit {
  vm$: BehaviorSubject<ProjectAuditLogsError> = new BehaviorSubject<ProjectAuditLogsError>(null);

  constructor(private presenter: ProjectAuditLogsErrorsPresenter) {}

  ngOnInit(): void {
    this.presenter.load(this.vm$);
  }

  clearErrors(): void {
    this.presenter.clearErrors();
  }
}
